.about-c{
  margin-top: -15em;
}
.circle-tick {
    width: 2.5em;
    height: 2.5em;
    background-color: #000080;
    border-radius: 9999px;
  }
  
.tick {
    color: white;
    font-size: 1.25em;
  }
@media only screen and (max-width: 640px) {
  /* For mobile phones: */
  div.overall{
    display: block;
  }
  /* img.about-image{
      display: none;
  } */
}
@media only screen and (max-width:768px){
  div.about-content{
      padding-left:2em;
  }
  p.p-content{
    padding: 0%;
  }
  img.about-image{
      height: 70vh;
      image-rendering: auto;
  }
  div.image-about{
      padding-top: -6em;
  }
}
@media only screen and (max-width: 1024px) {
  div.overall{
    padding:auto;
  }
  div.about-content{
    padding-left:1em;
  }
  p.p-content{
    padding: 0%;
  }
  img.about-image{
      image-rendering: crisp-edges;
      height: 100%;
      width:100%    
  }
  div.image-about{
      padding-top: 5em;
  }
}

@media only screen and (max-width: 1280px) {
  div.overall{
    padding:0%;
  }

  div.about-content{
    padding-left:2em;
  }
  p.p-content{
    padding: 0%;
  }
  img.home-image{
      image-rendering: crisp-edges;
      height: 100%;
      width:100%
  }
  div.image-home{
      padding-top: 5em;
  }
}