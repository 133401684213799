/*  import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap');



/* footer section styling */
.row1 {
    display: flex;
    flex-direction: row;
    
}
.copyright p{

      font-size: 0.85rem;
      text-align: center;
      color: #000;
      font-weight: 500;

}