/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap'); */
img.home-image{
    margin-top: -2em;
}
@media only screen and (max-width: 640px) {
    /* For mobile phones: */
    div.overall{
      display: block;
      margin: 0;
      
    }
    h1.first-header{
        font-size: 2em;
        line-height: normal;
        font-weight: 700;
    }
    /* div.image-home{
        display: none;

    } */
}
@media only screen and (max-width:768px){
    div.home-content{
        padding:0%;
    }
    img.home-image{
        height: 100vh;
        image-rendering: auto;
    }
    div.image-home{
        padding-top: 10em;
    }
}
@media only screen and (max-width: 1024px) {
    div.overall{
      padding:auto;
    }
    div.home-content{
        padding:0%;
    }
    
    h1.first-header{
        font-size: 2em;
        padding:auto;
        padding-top: 0%;
        line-height: normal;
        font-weight: 700;
    }
    img.home-image{
        image-rendering: crisp-edges;
        height: 100%;
        width:100%    
    }
    div.image-home{
        padding-top: 5em;
    }
}

@media only screen and (max-width: 1280px) {
    div.overall{
      padding:0%;
    }
    h1.first-header{
        font-size: 2em;
        padding:auto;
        padding-top: 0%;
        line-height: normal;
        font-weight: 700;
    }
    img.home-image{
        image-rendering: crisp-edges;
        height: 100%;
        width:100%
        /* height: 40vh; */
    }
    div.image-home{
        padding-top: 5em;
    }
}


    