.service-c{
    margin-top: -5em;
  }
.row2{
    flex-direction: row;
}
/* @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap');
.container h4{
    font-size: 28px;
    color: rgba(0, 0, 128, 0.8); 
    text-align: center;
    font-family: 'Poppins', sans-serif;

}
.services{
    display: flex;
    padding: 0 20px 0 10px;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    margin-top: 20px;
   
}


.title{
    color: #EE9910;
    padding-top: 0.4rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px; */
/* identical to box height */
    /* letter-spacing: 0.02e;
}
.title :hover{
    
    font-weight: 500;
}
.description{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-radius: 22px;
    letter-spacing: 0.06em;
    color: #000000; */
    /* overflow: auto; */
/* }
.description :hover{
    color: #fff;
    font-weight: 500;
}
.service{
    background: #F8F7F7;
    box-shadow: 20px 20px 30px rgba(0, 0,0, 0.06);
    cursor: pointer;
    transition:0.2 ease;
    border-radius: 20px;
    width: 350px;
    height: 100%;
    
} */
  /* overflow: hidden; */


